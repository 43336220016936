@import "src/scss/module";

.FooterAwards {
  div {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 4px;

    img {
      width: 100%;
    }
  }
}
