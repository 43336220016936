@import "src/scss/module";

.FooterBrochure {
  justify-content: space-between;
  align-items: center;
  margin-top: -3rem;

  img {
    max-width: 220px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
